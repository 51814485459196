.withVoting,
.withoutVoting {
  border-radius: 16px;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  gap: 2px;
  padding: 4px 8px;
}

.withoutVoting {
  background-color: #ca000020;
  color: #ca0000;
}

.withoutVoting span {
  color: #ca0000;
}

.withVoting {
  background-color: #22762220;
  color: #227622;
}

.withVoting span {
  color: #227622;
}